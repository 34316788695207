<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{
              name: 'DashboardHome',
            }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: 'SystemSettingsHome',
              }"
            >全系統管理</b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: 'OrganizationList',
              }"
            >組織管理</b-breadcrumb-item>
            <b-breadcrumb-item active>通路管理</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">{{ organizationName }}通路管理</h4>
        <div
          class="col-12 col-xl-6 d-flex flex-column flex-xl-row align-items-start align-items-xl-center"
        >
          <b-button
            v-if="checkPermission([consts.MERCHANT_MODIFY])"
            class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
            variant="primary"
            @click="handleCreate"
          >
            <i class="fa fa-plus"></i>新增通路
          </b-button>
          <b-input-group>
            <b-form-input placeholder="搜尋通路名稱" v-model="search" @keyup.enter="handleSearch"></b-form-input>
            <b-input-group-append>
              <b-button @click="handleSearch">
                <i class="fa fa-search"></i>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <b-table striped hover responsive :items="merchants" :fields="fields" :busy="isLoading">
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-button
            v-if="checkPermission([consts.MERCHANT_MODIFY])"
            variant="inverse-warning"
            :to="{
              name: 'OrgMerchantEdit',
              params: {
                orgId: orgId,
                merchantId: data.item.id,
              },
            }"
          >編輯</b-button>

          <b-button
            v-if="checkPermission([consts.MERCHANT_DELETE])"
            class="ml-2"
            variant="danger"
            :disabled="data.item.is_enabled === 1"
            @click="handleDelete(data.item.id, data.item.name, data.item.type)"
          >刪除</b-button>
        </template>
      </b-table>

      <div class="d-flex justify-content-center" style="margin-top: 80px">
        <b-pagination
          class="separated"
          v-model="currentPage"
          :total-rows="total"
          :per-page="perPage"
          aria-controls="merchantList"
          @change="(page) => fetchMerchants(page)"
        ></b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import merchantApi from "../../../apis/merchant";
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";

export default {
  data() {
    return {
      consts,
      isLoading: false,
      search: "",
      merchants: [],
      fields: [
        {
          key: "name",
          label: "通路名稱",
        },
        {
          key: "type",
          label: "通路類型",
        },
        {
          key: "id",
          label: "hashid",
        },
        {
          key: "actions",
          label: "管理",
        },
      ],
      organizationName: '',
      perPage: 15,
      currentPage: 1,
      total: 0,
    };
  },
  computed: {
    orgId() {
      return this.$route.params.orgId;
    },
  },
  mounted() {
    if (!this.checkPermission([consts.MERCHANT_LIST_VIEW])) {
      this.$swal
        .fire({
          type: "error",
          text: "你沒有權限訪問此頁面",
          confirmButtonColor: "#d33"
        })
        .then(() => {
          this.$router.push({ name: "OrganizationList" });
        });
    } else {
      this.fetchMerchants();
    }
  },
  methods: {
    async fetchMerchants(page) {
      try {
        this.isLoading = true;
        const { data } = await merchantApi.getMerchants(this.orgId, {
          keyword: this.search,
          page: page || this.currentPage,
          per_page: this.perPage || 15,
        });
        this.total = data.meta.total;
        this.perPage = data.meta.per_page;
        this.merchants = data.data;
        this.organizationName = data.meta.organization_name;
      } catch (error) {
        console.log("");
      }
      this.isLoading = false;
    },
    handleSearch() {
      this.fetchMerchants();
    },
    async handleDelete(merchantId, name, type) {
      this.$swal
        .fire({
          type: "warning",
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>通路名稱：${name}</div>
                <div>通路類型：${type}</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              this.isLoading = true;
              await merchantApi.deleteMerchant(this.orgId, merchantId);
              await this.fetchMerchants(this.orgId);
            } catch (error) {
              console.log("");
            }
            this.isLoading = false;
          }
        });
    },
    handleCreate() {
      this.$router.push({
        name: "OrgMerchantCreate",
      });
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    }
  },
};
</script>
